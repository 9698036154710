.body{
    background-color: whitesmoke;
    background-size: cover;
    position: "absolute";
    top: 0;
    left: 0;
    min-height: 100vh;
    width: "100%";
}



.centered{
    text-align: center;
}

.Title{
    text-align: center;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
}

.editbutton{
    float: right;
    margin-left: 10px;
 

}

.removeButton{
    float: right;
    margin-right: 20 px;
}

.addArticalButton{
    margin-top: 10px;
    margin-right: 10px;
    float: right;
}