.body{
    background-color: rgb(255,255,255);
    background-size: cover;
    position: "absolute";
    top: 0;
    left: 0;
    min-height: 100vh;
    width: "100%";
    background-image: url(../../Assets/background2.jpg);
}
.Container{
    padding: 20px;
}
.image{
    max-width: 25%;
    max-height: 25%;
    padding: 10px;
    position: relative;
    ;
}

.Book-appointment{
    margin: 20px;
padding:20px;
background-color: rgb(255,245,238);


width: 100%;
}

.About-Me{
  margin: 20px;
padding:20px;
background-color: rgb(255,245,238);

display: flex;
align-items: center;
width: 100%;
}

.Gallery{

  padding: 10px;
  background-color: rgb(255,245,238);

}

.Prices{
    margin: 20px;
    padding:50px;
    background-color: rgb(255,245,238);
    width: 100%;
    }
    .slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}


.slides {

    height: 30%;
    width: 80%;

  
  
  
}

.slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index:1;
}

.slides .slick-prev {
  left: 5%;
}

.slides .slick-next {
  right: 5%;
}