.navbar{
    background-color: #ffffff;
    padding: 10px;


}

.navbrand{
    max-width: 100px;
    max-height: 100px;
    padding-bottom: 20px;
}